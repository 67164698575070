///include /assets/js/plugins/network-property-set.js
class CheckoutInfo extends NetworkPropertySet {
    static get inst() {
        if(!this._inst) {
            if(!this.instClass) {
                throw new Error("No checkout info service loaded!")
            }
            this._inst = new this.instClass()
        }
        return this._inst
    }
    /**
     * @param {typeof CheckoutInfo} c
     */
    static register(c) {
        this.instClass = c
    }
    constructor() {
        super()
        /**
         * @type {?GET.Stack.Payment.$ns.config.checkoutInfo.output}
         */
        this.info = null
        /**
         * @type {?GET.Stack.Payment.$ns.config.output}
         */
        this.payment = null
        this.labelByType = null;
        this.userSetSubscription = {
            "card":     false,
            "paypal":   false,
            "paystack":   false,
            "razorpay":   false,
            "paddle":   false,
            "transfer": false,
            "debit":    false,
            "balance":    false
        };
    }
    get networkPropertyHandlers() {
        return {
            info: async () => {
                /**
                 * @type {GET.Stack.Payment.$ns.config.checkoutInfo.output}
                 */
                const info = await $.ajax(`/n/checkoutInfo`)
                if(info.expires) {
                    const expires_at = new Date(info.expires)
                    setTimeout(
                        () => this.reload(["info"]),
                        expires_at.valueOf() - (new Date()).valueOf() - 1000
                    )
                }
                if( info.paypal?.userSetSubscription ) {
                    this.userSetSubscription.paypal = true;
                }
                this.labelByType = {
                    "card": info.card ? info.card.checkoutLabel : null,
                    "paypal": info.paypal ? info.paypal.checkoutLabel : null,
                    "paystack": info.paystack ? info.paystack.checkoutLabel : null,
                    "paddle": info.paddle ? info.paddle.checkoutLabel : null,
                    "transfer": info.transfer ? info.transfer.checkoutLabel : null,
                    "debit": info.debit ? info.debit.checkoutLabel : null,
                    "balance": info.balance ? info.balance.checkoutLabel : null,
                    "razorpay": info.razorpay ? info.razorpay.checkoutLabel : null
                };
                return info
            }
        }
    }
    get supportedPaymentTypes() {
        if(!this.info) return null
        let currency = (AnyBasket.inst).currency;
        if( !currency ) return null;

        const payment_types = []
        if(
            this.info.card &&
            (
                !this.info.card.currencies ||
                this.info.card.currencies.length == 0 ||
                this.info.card.currencies.includes(currency)
            )
        ) {
            payment_types.push("card")
        }
        if(
            this.info.directDebit &&
            (
                !this.info.directDebit.currencies ||
                this.info.directDebit.currencies.length == 0 ||
                this.info.directDebit.currencies.includes(currency)
            )
        ) {
            payment_types.push("debit")
        }
        if(
            this.info.paypal &&
            (
                !this.info.paypal.currencies ||
                this.info.paypal.currencies.length == 0 ||
                this.info.paypal.currencies.includes(currency)
            )
        ) {
            payment_types.push("paypal")
        }
        if(
            this.info.paystack &&
            (
                !this.info.paystack.currencies ||
                this.info.paystack.currencies.length == 0 ||
                this.info.paystack.currencies.includes(currency)
            )
        ) {
            payment_types.push("paystack")
        }
        if(
            this.info.paddle &&
            (
                !this.info.paddle.currencies ||
                this.info.paddle.currencies.length == 0 ||
                this.info.paddle.currencies.includes(currency)
            )
        ) {
            payment_types.push("paddle")
        }
        if(
            this.info.transfer &&
            (
                !this.info.transfer.currencies ||
                this.info.transfer.currencies.length == 0 ||
                this.info.transfer.currencies.includes(currency)
            )
        ) {
            payment_types.push("transfer")
        }
        if(
            this.info.balance &&
            (
                !this.info.balance.currencies ||
                this.info.balance.currencies.length == 0 ||
                this.info.balance.currencies.includes(currency)
            )
        ) {
            payment_types.push("balance")
        }
        if(
            this.info.razorpay &&
            (
                !this.info.razorpay.currencies ||
                this.info.razorpay.currencies.length == 0 ||
                this.info.razorpay.currencies.includes(currency)
            )
        ) {
            payment_types.push("razorpay")
        }
        return payment_types
    }
    get usesProformaInvoices() {
        return false
    }
    /**
     * Returns the subset of real_payment_types which are actually supported.
     *
     * @param {string[]} real_payment_types
     * @returns {?string[]}
     */
    usablePaymentTypes(real_payment_types) {
        const supported = this.supportedPaymentTypes
        if(!supported) return null
        return real_payment_types.filter(t => supported.includes(t))
    }
}
